/* eslint-disable @typescript-eslint/naming-convention */
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { user } from '@features/user/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getMerchantIdVerification } from '@presentation/kyc/atoms/utils/get-verification-object';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { Subject, Subscription } from 'rxjs';
import {
  GetWithdrawalsListRequestModel,
  WalletModel,
} from 'src/app/core/domain/wallet/wallet.model';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { GetMarketClosingReasonUseCase } from 'src/app/core/usecases/market-availability/get-closing-reason.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { GetWalletsUseCase } from 'src/app/core/usecases/wallet/get-wallets.usecase';
import { GetWithdrawalsListUseCase } from 'src/app/core/usecases/wallet/get-withdrawals-list.usecase';
import {
  FEATURE_FLAGS,
  PAYMENT_REQUESTS_CONSTS,
  PREPAID_ORDERS,
  WALLET_SURVEY_NAME,
} from 'src/app/presentation/shared/constants';
import { Currency } from 'src/app/presentation/shared/interfaces/countries';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { IsFeatureEnabledUseCase } from '../../core/usecases/market-availability/is-feature-enabled.usecase';
import { LocalizedComponent } from '../base/localized.component';
import { ENGLISH_LANGUAGE } from '../shared/constants/country-language-codes-mapping.constants';
import { WEB_WALLET_TRANSACTION_SURVEY } from '../shared/constants/feature-flags';
import { PaymentRequestMethodPipe } from '../shared/pipes/payment-request-method.pipe';
import { PaymentRequestStatusPipe } from '../shared/pipes/payment-request-status.pipe';
import { featureAttributeAssign } from '../shared/utilities/feature-attribute-assign.utility';
import { WalletCardComponent } from './wallet-card/wallet-card.component';
import { WalletTransactionHistoryComponent } from './wallet-transaction-history/wallet-transaction-history.component';
import { WalletTransactionSurveyComponent } from './wallet-transaction-survey/wallet-transaction-survey.component';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    WalletCardComponent,
    WalletTransactionHistoryComponent,
    WalletTransactionSurveyComponent,
    NgClass,
    FormsModule,
    PaginationModule,
    AsyncPipe,
    TranslateModule,
    PaymentRequestMethodPipe,
    PaymentRequestStatusPipe,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
  ],
})
export class WalletComponent extends LocalizedComponent implements OnInit, OnDestroy {
  wallet = [
    {
      totalProfit: 0,
      countOrders: 0,
      eligibleProfit: 0,
      deliveredOrders: 0,
      inprogressProfit: 0,
      inprogressOrders: 0,
      incomingProfit: 0,
      receivedOrders: 0,
    },
  ];

  requests: any = [];

  allRequests = [];

  public showPagination: boolean;

  public showBoundaryLinks = true;

  public noOfItems: number;

  public maxPageSize = 6;

  public currentPage = 1;

  public loading = false;

  public shouldHideTransactionSubtitle = true;

  public paymentRequestsStatuses = PAYMENT_REQUESTS_CONSTS.PAYMENT_REQUESTS_STATUSES;

  public maxItemPerPage = 5;

  public currenciesFilterOptions: Currency[];

  public selectedFilterCurrency = '';

  public statusesFilterOptions: string[] = [];

  public selectedFilterStatus = '';

  public wallets: WalletModel[];

  public withdrawalsRequestsFilter: GetWithdrawalsListRequestModel;

  public moreClickedSubject: Subject<void> = new Subject<void>();

  public isEnglishLanguage = false;

  private _languageChangeSubscription: Subscription;

  public hasPrepaidFeature = false;

  public closingMessage: string;

  public isIdVerified = true;

  public verificationIdRejectionReason = '';

  public isWithdrawalsDisabled = false;

  public verificationStatus?: 'PENDING' | 'REJECTED';

  public isDuplicateAccount = false;

  constructor(
    private _getWithdrawalsListUseCase: GetWithdrawalsListUseCase,
    private multitenancyService: MultitenancyService,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _translateService: TranslateService,
    private _matDialog: MatDialog,
    private _getQuestionnaireEligibilityUseCase: GetQuestionnaireEligibilityUseCase,
    private _getFeatureAttributeCase: GetFeatureAttributeUsecase,
    private _getWalletsUseCase: GetWalletsUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    public _getMarketClosingReasonUseCase: GetMarketClosingReasonUseCase,
    private _isFeatureEnabledUseCase: IsFeatureEnabledUseCase,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setWithdrawalListSubtitleVisibility();
    this._getLanguage();
    this.setStatusesFilterOptions();
    this.setCurrenciesFilterOptions();
    this.getWallets();
    this.getWithdrawalRequests();
    this.openWalletSurvey();
    this._getMarketClosureMessage();
    this.hasPrepaidFeature = this._checkUserFeatureExistsUseCase.execute(PREPAID_ORDERS);

    this.checkIfWithdrawalIsDisabled();

    this.checkMerchantIdVerification();
  }

  protected checkIfWithdrawalIsDisabled(): void {
    this._isFeatureEnabledUseCase.execute('withdrawals').subscribe((res) => {
      if (!res) {
        this.isWithdrawalsDisabled = true;
      }
    });
  }

  protected checkMerchantIdVerification(): void {
    getMerchantIdVerification(
      this._getFeatureFlagUsecase,
      this._checkUserFeatureExistsUseCase,
    ).then((response) => {
      this.isIdVerified = response.isVerified;
      this.isDuplicateAccount = response.isDuplicate;
      this.verificationStatus = response.status;

      if (response.rejectionReason)
        this.verificationIdRejectionReason = this.isRTL
          ? response.rejectionReason.ar
          : response.rejectionReason.en;
    });
  }

  private _getMarketClosureMessage(): void {
    this._getMarketClosingReasonUseCase.execute('withdrawals').subscribe((res) => {
      if (res) {
        this.closingMessage = res;
      }
    });
  }

  public openWalletSurvey(): void {
    this._getFeatureAttributeCase.execute(WEB_WALLET_TRANSACTION_SURVEY).subscribe({
      next: (attribute) => {
        const access = featureAttributeAssign(attribute, user.id);
        if (access) {
          this._getQuestionnaireEligibilityUseCase
            .execute(WALLET_SURVEY_NAME)
            .subscribe((isEnabled) => {
              if (isEnabled.enabled) {
                this._matDialog.open(WalletTransactionSurveyComponent, {
                  width: '700px',
                });
              }
            });
        }
      },
    });
  }

  setWithdrawalListSubtitleVisibility(): void {
    this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.BULK_PRE_ORDERS_EXPERIMENT).subscribe({
      next: (bulkPreorderExpirementFeatureFlag) => {
        if (!bulkPreorderExpirementFeatureFlag) {
          this.shouldHideTransactionSubtitle = false;
        }
      },
    });
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getWithdrawalRequests();
  }

  convertDate(mongo: any) {
    if (mongo) {
      const date = new Date(mongo);
      return date.toLocaleDateString('en-US');
    }
    return '';
  }

  refreshWalletsValues(): void {
    this.getWallets();
    this.getWithdrawalRequests();
  }

  setCurrenciesFilterOptions(): void {
    this.loading = true;
    this.multitenancyService.getSupportedCountries().then((countries) => {
      this.loading = false;
      this.currenciesFilterOptions = countries.map((country) => country.currency);
    });
  }

  setStatusesFilterOptions(): void {
    for (const statusKey in PAYMENT_REQUESTS_CONSTS.PAYMENT_REQUESTS_STATUSES) {
      if (PAYMENT_REQUESTS_CONSTS.PAYMENT_REQUESTS_STATUSES.hasOwnProperty(statusKey)) {
        const status = PAYMENT_REQUESTS_CONSTS.PAYMENT_REQUESTS_STATUSES[statusKey];
        this.statusesFilterOptions = [...this.statusesFilterOptions, status];
      }
    }
  }

  onChangeCurrencyFilterSelection(): void {
    const currency = this.selectedFilterCurrency ? this.selectedFilterCurrency : undefined;
    this.withdrawalsRequestsFilter = {
      ...this.withdrawalsRequestsFilter,
      currency,
    };
    this.currentPage = 1;
    this.getWithdrawalRequests();
  }

  onChangeStatusFilterSelection(): void {
    const status = this.selectedFilterStatus ? [this.selectedFilterStatus] : undefined;
    this.withdrawalsRequestsFilter = {
      ...this.withdrawalsRequestsFilter,
      status,
    };
    this.currentPage = 1;
    this.getWithdrawalRequests();
  }

  getWallets(): void {
    this._getWalletsUseCase.execute().subscribe({
      next: (wallets) => {
        this.wallets = wallets;
      },
    });
  }

  getWithdrawalRequests(): void {
    this.withdrawalsRequestsFilter = {
      ...this.withdrawalsRequestsFilter,
      pageSize: this.maxItemPerPage,
      page: this.currentPage,
    };
    this._getWithdrawalsListUseCase.execute(this.withdrawalsRequestsFilter).subscribe({
      next: (response) => {
        this.requests = response.withdrawals;
        this.noOfItems = response.count;
        this.showPagination = !!response.count;
      },
    });
  }

  private _getLanguage(): void {
    this.isEnglishLanguage = this._translateService.currentLang === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: ({ lang }: { lang: string }) => {
        this.isEnglishLanguage = lang === ENGLISH_LANGUAGE;
        this._getMarketClosureMessage();
      },
    });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
