<div class="cover">
  <div class="title">{{ 'WALLET.TITLE' | translate }}</div>
  <div class="sub-title">{{ 'WALLET.SUBTITLE' | translate }}</div>
</div>

<div class="background">
  <div
    [ngClass]="{
      'container-custom-mob': isMobile,
      'container-custom': !isMobile
    }"
  >
    <div>
      <div class="card main-login">
        <div *ngIf="errorMessage && !loading" class="alert alert-danger form-error-message">
          {{ errorMessage }}
        </div>
        <div *ngIf="successMessage && !loading" class="alert alert-success">
          {{ successMessage }}
        </div>

        <!-- <loader [loading]="loading"></loader> -->
        <div class="card-content">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label>{{ 'WALLET.WALLET_LOGIN.PASSWORD' | translate }}</label>
              <input
                class="form-control"
                type="password"
                placeholder="{{ 'WALLET.WALLET_LOGIN.ENTER_PASSWORD_PROMPT' | translate }}"
                formControlName="password"
              />
              <div *ngIf="password.invalid && submitted">
                <div *ngIf="password.errors?.required" class="input-error">
                  {{ 'WALLET.WALLET_LOGIN.PASSWORD_REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <button
              class="btn btn-register"
              [ngClass]="{ 'btn-register-mob': isMobile }"
              type="submit"
            >
              {{ 'WALLET.WALLET_LOGIN.LOGIN' | translate }}
            </button>
            <div class="foot-div">
              <p class="foot-label">
                {{ 'WALLET.WALLET_LOGIN.FORGOT_WALLET_QUESTION' | translate }}
              </p>
              <button
                class="btn foot-label-2"
                (click)="onClickForgotPassword(); $event.preventDefault()"
              >
                {{ 'WALLET.WALLET_LOGIN.FORGOT_WALLET_EMAIL' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
