<div
  class="game-card"
  [ngClass]="{
    'game-card--active': gameUnlocked,
    'game-card--inactive': !gameUnlocked
  }"
>
  <img
    loading="lazy"
    class="game-card__img"
    src="assets/img/rewards-program-icons/carnival.svg"
    alt=""
  />
  <img
    loading="lazy"
    *ngIf="!gameUnlocked"
    class="game-card__img--locked"
    src="assets/img/rewards-program-icons/lock.svg"
    alt=""
  />

  <div>
    <p class="game-card__title">كرنڤال الارباح</p>
    <p *ngIf="gameUnlocked" class="game-card__description">
      كل ١ طلب =+{{ gameProfitFactor }} جنيه مصري
    </p>
    <p *ngIf="!gameUnlocked" class="game-card__title">سيتم فتحه بعد قيامك بإنهاء المستوى الرابع</p>
  </div>
</div>
