/* eslint-disable @angular-eslint/use-pipe-transform-interface */
import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CASH_INCENTIVE_PAYMENT_METHOD_HELPER_TEXT_FEATURE } from '../constants/feature-flags';
import { CASH_INCENTIVE_PAYMENT_REQUEST_TYPE, PAYMENT_REQUEST_TYPES } from '../constants/wallet';

@Pipe({
  name: 'paymentRequestMethod',
  standalone: true,
})
export class PaymentRequestMethodPipe implements PipeTransform {
  constructor(private readonly _getFeatureFlagUsecase: GetFeatureFlagUsecase) {}

  transform(methodId: string): Observable<string> {
    return this._getFeatureFlagUsecase
      .execute(CASH_INCENTIVE_PAYMENT_METHOD_HELPER_TEXT_FEATURE)
      .pipe(
        map((flag) => {
          let paymetRequestTypes = PAYMENT_REQUEST_TYPES;
          if (flag) {
            paymetRequestTypes = [...PAYMENT_REQUEST_TYPES, CASH_INCENTIVE_PAYMENT_REQUEST_TYPE];
          }
          const filteredMethod = paymetRequestTypes.filter(
            (method) => method.value === methodId,
          )[0];
          return filteredMethod?.name || '';
        }),
      );
  }
}
