import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, MatProgressBarModule],
})
export class MilestoneComponent implements OnInit {
  @Input() order: number;

  @Input() target: number;

  @Input() reward: number;

  @Input() progress: number;

  @Input() milestonesCount: number;

  @Input() currency: string;

  @Input() currentOrders: number;

  arabicOrder: string;

  ngOnInit(): void {
    this.setArabicOrder();
  }

  setArabicOrder() {
    switch (this.order) {
      case 0:
        this.arabicOrder = 'الأول';
        break;
      case 1:
        this.arabicOrder = 'الثاني';
        break;
      case 2:
        this.arabicOrder = 'الثالث';
        break;
      case 3:
        this.arabicOrder = 'الرابع';
        break;
      case 4:
        this.arabicOrder = 'الخامس';
        break;
      default:
        this.arabicOrder = `${this.order}`;
        break;
    }
  }
}
