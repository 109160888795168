/* eslint-disable @typescript-eslint/naming-convention */
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  CustomMilestone,
  IncentiveProgramModel,
} from 'src/app/core/domain/incentive-program.model';
import { MilestoneComponent } from './milestone/milestone.component';

@Component({
  selector: 'app-rewards-progress-bar',
  templateUrl: './rewards-progress-bar.component.html',
  styleUrls: ['./rewards-progress-bar.component.scss'],
  standalone: true,
  imports: [NgFor, MilestoneComponent, NgIf, NgClass],
})
export class RewardsProgressBarComponent implements OnInit {
  @Input() incentiveProgram: IncentiveProgramModel;

  @Input() currency: string;

  milestones: CustomMilestone[];

  runnersProgress = 0;

  progressPerecentage = 0;

  currentOrders = 0;

  target = 0;

  ngOnInit(): void {
    this.setupMilestonesData();
    this.calculateProgress();
  }

  setupMilestonesData() {
    this.target = this.incentiveProgram.target;
    this.currentOrders = this.incentiveProgram.currentOrders;
    const _milestones = this.incentiveProgram.milestones;

    // to calculate progress between only two milestones as a percentage
    this.milestones = _milestones.map((milestone, i) => {
      const customMilestone: CustomMilestone = milestone as CustomMilestone;
      if (this.currentOrders === 0) {
        customMilestone.progress = 0;
      } else if (this.currentOrders === _milestones[_milestones.length - 1].target) {
        customMilestone.progress = 100;
      } else {
        if (this.currentOrders >= customMilestone.target) {
          customMilestone.progress = 100;
        } else {
          customMilestone.progress =
            // check if smaller than the first milestone, subtract 0
            /* when current order is bigger than previous milestone but smaller than the current;
            to calculate percentage between only those two milestones the formula is
            (current orders - previous milestone / current milestone - previous milestone) */
            i >= 1
              ? ((this.currentOrders - _milestones[i - 1].target) /
                  (_milestones[i].target - _milestones[i - 1].target)) *
                100
              : (this.currentOrders / _milestones[i].target) * 100;
        }
      }
      return customMilestone;
    });
  }

  calculateProgress() {
    this.progressPerecentage = Math.round(
      (this.incentiveProgram.currentOrders / this.incentiveProgram.target) * 100,
    );

    // Similar to the calculations done above, this calculates the progress for nonequidistant percentages
    for (let i = 0; i < 4; i++) {
      if (this.currentOrders >= this.milestones[i].target) {
        this.runnersProgress += 25;
      } else {
        this.runnersProgress =
          i >= 1
            ? this.runnersProgress +
              ((this.currentOrders - this.milestones[i - 1].target) /
                (this.milestones[i].target - this.milestones[i - 1].target)) *
                25
            : this.runnersProgress + (this.currentOrders / this.milestones[i].target) * 25;
        break;
      }
    }
  }
}
