<div class="wallet-wrapper">
  <p *ngIf="closingMessage" class="wallet-unavailability-message">
    {{ closingMessage }}
  </p>
  <div class="title">{{ 'WALLET.TITLE' | translate }}</div>
  <!-- verification time disclaimer -->
  <div class="body2--bold information-text" *ngIf="verificationStatus === 'PENDING'">
    <img src="assets/img/info-circle.svg" />
    {{ trans('kyc-warnings.pending') }}
  </div>
  <div class="body2--bold information-text error-text" *ngIf="verificationStatus === 'REJECTED'">
    <img src="assets/img/info-circle.svg" class="error-icon" />
    {{ trans('kyc-warnings.rejection') }}
    {{ verificationIdRejectionReason }}.
    {{ trans('kyc-warnings.retry') }}
  </div>
  <div class="sub-title">{{ 'WALLET.SUBTITLE' | translate }}</div>

  <div class="wallet-cards-container {{ hasPrepaidFeature ? 'has-prepaid' : 'has-no-prepaid' }}">
    <app-wallet-card
      *ngFor="let wallet of wallets"
      [walletData]="wallet"
      [isDuplicateAccount]="isDuplicateAccount"
      [shouldPromptToVerifyId]="verificationStatus === 'REJECTED' || !isIdVerified"
      (withdrawalRequested)="refreshWalletsValues()"
      [isWithdrawalsDisabled]="isWithdrawalsDisabled || verificationStatus === 'PENDING'"
    ></app-wallet-card>
  </div>

  <br />

  <app-wallet-transaction-history
    *ngIf="currenciesFilterOptions?.length"
    [currenciesOptions]="currenciesFilterOptions"
  ></app-wallet-transaction-history>

  <div class="recent-transactions-title">{{ 'WALLET.RECENT_TRANSACTIONS.TITLE' | translate }}</div>
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div *ngIf="!shouldHideTransactionSubtitle" class="recent-transactions-subtitle">
      {{ 'WALLET.RECENT_TRANSACTIONS.SUBTITLE' | translate }}
    </div>
    <div class="payment-requests-filters-wrapper d-flex justify-content-between align-items-center">
      <div class="currency-filter-wrapper">
        <label for="currency-filter" class="filter-label">{{
          'WALLET.RECENT_TRANSACTIONS.CURRENCY' | translate
        }}</label>
        <select
          name="currency-filter"
          class="filter-control"
          [(ngModel)]="selectedFilterCurrency"
          (change)="onChangeCurrencyFilterSelection()"
        >
          <option value="">{{ 'WALLET.RECENT_TRANSACTIONS.ALL' | translate }}</option>
          <option *ngFor="let currency of currenciesFilterOptions" [value]="currency.englishName">
            {{ isEnglishLanguage ? currency.englishName : currency.arabicName }}
          </option>
        </select>
      </div>
      <div class="status-filter-wrapper">
        <label for="status-filter" class="filter-label">{{
          'WALLET.RECENT_TRANSACTIONS.STATUS' | translate
        }}</label>
        <select
          name="status-filter"
          class="filter-control"
          [(ngModel)]="selectedFilterStatus"
          (change)="onChangeStatusFilterSelection()"
        >
          <option value="">{{ 'WALLET.RECENT_TRANSACTIONS.ALL' | translate }}</option>
          <option *ngFor="let status of statusesFilterOptions" [value]="status">
            {{ status | paymentRequestStatus | translate }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <table *ngIf="requests.length > 0" class="table d-none d-md-table">
    <thead>
      <tr>
        <th scope="col">{{ 'WALLET.RECENT_TRANSACTIONS.AMOUNT' | translate }}</th>
        <th scope="col">{{ 'WALLET.RECENT_TRANSACTIONS.CURRENCY' | translate }}</th>
        <th scope="col">{{ 'WALLET.RECENT_TRANSACTIONS.PAYMENT_METHOD' | translate }}</th>
        <th scope="col">{{ 'WALLET.RECENT_TRANSACTIONS.BENEFICIARY' | translate }}</th>
        <th scope="col">{{ 'WALLET.RECENT_TRANSACTIONS.STATUS' | translate }}</th>
        <th scope="col">{{ 'WALLET.RECENT_TRANSACTIONS.DATE' | translate }}</th>
        <th scope="col">{{ 'WALLET.RECENT_TRANSACTIONS.REFUSAL_REASON' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let request of requests">
        <td>{{ request.amount < 0 ? request.amount * -1 : request.amount }}</td>
        <td>{{ request.currency }}</td>
        <td>{{ request.paymentMethod | paymentRequestMethod | async }}</td>
        <td>{{ request.phoneNum }}</td>
        <td class="{{ request.status }}">
          {{ request.status | paymentRequestStatus | translate }}
        </td>
        <td>{{ convertDate(request.createdAt) }}</td>
        <td>{{ request.rejectReason }}</td>
      </tr>
    </tbody>
  </table>
  <div class="mobile-wallet-transaction d-block d-md-none" *ngFor="let request of requests">
    <div class="d-flex justify-content-between">
      <p class="bold mobile-request-amount">
        {{ request.amount < 0 ? request.amount * -1 : request.amount }}
        {{ request.currency ? request.currency : 'جنيه مصري' }}
      </p>
      <p>{{ request.paymentMethod | paymentRequestMethod | async }} ({{ request.phoneNum }})</p>
    </div>
    <div class="d-flex justify-content-between">
      <p
        class="bold"
        [ngClass]="{
          'payment_status--accepted': request.status === paymentRequestsStatuses.REQUEST_ACCEPTED,
          'payment_status--rejected': request.status === paymentRequestsStatuses.REQUEST_REJECTED,
          'payment_status--pending': request.status === paymentRequestsStatuses.REQUEST_PENDING
        }"
      >
        {{ request.status | paymentRequestStatus }}
        <span *ngIf="request.rejectReason"> ({{ request.rejectReason }}) </span>
      </p>
      <p class="bold">{{ convertDate(request.createdAt) }}</p>
    </div>
  </div>
  <div class="mobile-wallet-transaction" *ngIf="requests.length === 0">
    <p class="text-center">{{ 'WALLET.TRANSACTION_HISTORY.TABLE.NO_RESULTS' | translate }}</p>
  </div>
  <div class="d-flex flex-column justify-content-center mb-5 mt-5 paginate">
    <div *ngIf="showPagination">
      <div class="pagination-wrapper d-flex justify-content-center">
        <pagination
          [totalItems]="noOfItems"
          [(ngModel)]="currentPage"
          [itemsPerPage]="maxItemPerPage"
          [maxSize]="maxPageSize"
          (pageChanged)="pageChanged($event)"
          previousText=" "
          nextText=" "
        >
        </pagination>
      </div>
    </div>
  </div>
  <br />
</div>
