<section class="card--taagerTeal">
  <section class="incentive-program__info">
    <img
      loading="lazy"
      class="image__rewardsProgram"
      src="{{ rewardsProgramAssetsUrl + 'truck.svg' }} "
      alt="money-bag.svg"
    />
    <div class="counter_card">
      <div class="orders">
        <p class="title">اجمالي الطلبات التي تم توصيلها</p>
        <p class="value">{{ incentiveProgram?.currentOrders }} طلب</p>
      </div>
      <div class="orders direction-ltr" *ngIf="deliveryRateIncentiveFeatureFlag">
        <p class="title">نسبة التوصيل ({{ incentiveProgram?.name }})</p>
        <p class="value">{{ operationRates?.deliveryRate }} %</p>
        <img
          loading="lazy"
          src="{{ rewardsProgramAssetsUrl + 'click-animation.gif' }}"
          alt=""
          class="tooltip__img delivery-rate-tooltip"
          (click)="toggleDeliveryRateTooltip()"
        />
      </div>
      <app-delivery-rate-tooltip
        *ngIf="showDeliveryRateToolTip"
        (dismissTooltip$)="toggleDeliveryRateTooltip()"
      ></app-delivery-rate-tooltip>
      <div class="profits">
        <img
          loading="lazy"
          class="profits__img"
          src="{{ rewardsProgramAssetsUrl + 'locked-coin.svg' }} "
          alt="coin.svg"
        />
        <div>
          <p class="title">ربحك المتوقع</p>
          <p class="value profits__value">+{{ incentiveProgram.currentReward }} {{ currency }}</p>
        </div>
        <img
          loading="lazy"
          src="{{ rewardsProgramAssetsUrl + 'click-animation.gif' }}"
          alt=""
          class="tooltip__img"
          (click)="toggleDeliveredTooltip()"
        />
      </div>
      <app-delivered-tooltip
        *ngIf="showDeliveredTooltip"
        (dismissTooltip$)="toggleDeliveredTooltip()"
      ></app-delivered-tooltip>
    </div>
    <div class="incentive-program-name">
      <p class="incentive-program-name__title">تحدي الاحتراف</p>
      <p class="incentive-program-name__value">{{ incentiveProgram.name }}</p>
    </div>
  </section>
  <app-rewards-progress-bar
    [incentiveProgram]="incentiveProgram"
    [currency]="currency"
  ></app-rewards-progress-bar>
  <section class="game-info">
    <div class="game-date">
      <img
        loading="lazy"
        class="date-img"
        src="{{ rewardsProgramAssetsUrl + 'calendar.svg' }} "
        alt="calendar"
      />
      <p
        *ngIf="
          !cappedIncentiveProgramFeatureFLag ||
          !cappedUser ||
          (cappedUser && incentiveProgram.currentOrders < incentiveProgram.target)
        "
        class="date-text"
      >
        اللعبة سارية من {{ incentiveProgram.startDate }} حتى {{ incentiveProgram.endDate }}.
      </p>
      <p
        *ngIf="
          cappedIncentiveProgramFeatureFLag &&
          cappedUser &&
          incentiveProgram.currentOrders >= incentiveProgram.target
        "
        class="date-text"
      >
        سوف تتجدد اللعبة بعد يوم {{ incentiveProgram.endDate }}.
      </p>
    </div>
    <app-game-card
      *ngIf="
        !cappedIncentiveProgramFeatureFLag || (cappedIncentiveProgramFeatureFLag && !cappedUser)
      "
      [gameProfitFactor]="uncappedGameFactor"
      [gameUnlocked]="gameUnlocked"
    ></app-game-card>
    <div *ngIf="cappedIncentiveProgramFeatureFLag && cappedUser" class="capped-user-game-card">
      <p class="capped-user-game-card__text">
        بعد اتمام المستوى الرابع، سيتوقف عد الارباح عند آخر ربح حققته
      </p>
    </div>
  </section>
</section>
