import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentRequestStatus',
  standalone: true,
})
export class PaymentRequestStatusPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'accepted':
        return 'WALLET.RECENT_TRANSACTIONS.STATUSES.ACCEPTED';
      case 'rejected':
        return 'WALLET.RECENT_TRANSACTIONS.STATUSES.REJECTED';
      case 'pending':
        return 'WALLET.RECENT_TRANSACTIONS.STATUSES.PENDING';
      case 'in_progress':
        return 'WALLET.RECENT_TRANSACTIONS.STATUSES.IN_PROGRESS';
      case 'processing':
        return 'WALLET.RECENT_TRANSACTIONS.STATUSES.PROCESSING';
      case 'returned':
        return 'WALLET.RECENT_TRANSACTIONS.STATUSES.RETURNED';
      default:
        throw new Error(`Invalid value: ${value}`);
    }
  }
}
