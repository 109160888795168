<div class="wallet-transactions" *ngIf="shouldShowTransactionTable">
  <div class="wallet-transactions__header">
    <i class="icon icon-wallet-transactions-history wallet-transactions__icon"></i>
    <div class="wallet-transactions__header-text">
      <p class="body1--bold content-main-color">
        {{ 'WALLET.TRANSACTION_HISTORY.HEADER.TEXT' | translate }}
      </p>
      <p class="caption2--medium content-medium-color">
        {{ 'WALLET.TRANSACTION_HISTORY.HEADER.SUB_TEXT' | translate }}
      </p>
    </div>
  </div>
  <app-wallet-transactions-history-filters
    [currenciesOptions]="currenciesOptions"
    [transactionsHistoryFilterForm]="transactionsHistoryFilterForm"
    (refreshWalletTransactionsEmitter)="getWalletTransactionsHistory()"
    (downloadExcelSheetEmitter)="onDownloadExcel()"
  ></app-wallet-transactions-history-filters>

  <loader [loading]="isLoading" class="wallet-transactions-table__loader"></loader>
  <app-wallet-transactions-table
    *ngIf="isLoaded"
    [transactions]="transactions"
  ></app-wallet-transactions-table>

  <pagination
    *ngIf="transactions?.length"
    [totalItems]="transactionsCount"
    [(ngModel)]="currentPage"
    [itemsPerPage]="pageSize"
    [maxSize]="10"
    (pageChanged)="pageChanged($event)"
    [boundaryLinks]="true"
    [directionLinks]="false"
    class="caption1--bold"
    firstText="{{ 'WALLET.TRANSACTION_HISTORY.TABLE.FIRST' | translate }}"
    lastText="{{ 'WALLET.TRANSACTION_HISTORY.TABLE.LAST' | translate }}"
  >
  </pagination>
</div>
