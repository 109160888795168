import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-delivered-tooltip',
  templateUrl: './delivered-tooltip.component.html',
  styleUrls: ['./delivered-tooltip.component.scss'],
  standalone: true,
})
export class DeliveredTooltipComponent {
  @Output() dismissTooltip$: EventEmitter<boolean>;

  constructor() {
    this.dismissTooltip$ = new EventEmitter<boolean>();
  }

  public dismissTooltip(): void {
    this.dismissTooltip$.next(true);
  }
}
