<div
  class="wallet-survey"
  [ngClass]="isEnglishLanguage ? 'dir-ltr' : 'dir-rtl'"
  *ngIf="questionsList"
>
  <img
    loading="lazy"
    class="close-btn"
    (click)="closeDialog()"
    src="{{ assetsPath + 'close' }}.svg"
  />
  <div class="questionnaire-container" *ngFor="let question of questionsList?.questions">
    <div class="question">
      <p>{{ isEnglishLanguage ? question.textEnglish : question.textArabic }}</p>
    </div>
    <div class="answer-container">
      <div
        class="answer"
        *ngFor="let answer of question.answers; let i = index"
        [ngClass]="{ 'selected-answer': i === selectedIndex }"
        (click)="selectAnswer(i, question)"
      >
        <img class="icon" [src]="answer.iconUrl" />
        <p class="text">{{ answer.textEnglish }}</p>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button
      class="btn mat-raised-button survey-button submit-button caption1--bold"
      (click)="submitAnswer()"
      [disabled]="!answerId"
    >
      {{ 'WALLET.WALLET_SURVEY.SUBMIT' | translate }}
    </button>
    <button
      class="btn mat-raised-button survey-button cancel-button caption1--bold"
      (click)="declineQuestionnaire()"
    >
      {{ 'WALLET.WALLET_SURVEY.DECLINE' | translate }}
    </button>
  </div>
</div>
