import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { country } from '@features/country/data';
import cache from '@mongez/cache';
import { combineLatest, map, Observable } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import {
  CREATIVE_REQUEST_FEATURE,
  FEATURE_FLAGS,
  LOYALTY_PROGRAM_FEATURE,
  MERCHANT_INSIGHTS_USER_FEATURE,
  PRE_ORDER_USER_FEATURE,
  REDIRECT_URL,
  REFERRAL_PROGRAM_FEATURE,
  STATICS_FEATURE,
  WALLET,
  WALLET_FEATURE,
} from 'src/app/presentation/shared/constants';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import {
  CREATIVES_FEATURE,
  LOYALTY_PROGRAM,
  MERCHANT_INSIGHTS,
  ONBOARDING_V2,
  REFERRAL_PROGRAM,
  WEB_STORES,
  ZID_INTEGRATION,
} from '../constants/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,

    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const notLoggedInToWallet =
      this._checkUserFeatureExistsUseCase.execute(WALLET_FEATURE) &&
      !this.localStorageService.getStorage(WALLET);

    if (state.url === this._appURLs.WALLET_URL) {
      if (notLoggedInToWallet) {
        cache.set(REDIRECT_URL, state.url);
        this.router.navigate([this._appURLs.WALLET_AUTH_URL]);
        return false;
      }

      this.localStorageService.remove(WALLET);

      return true;
    }

    if (state.url === this._appURLs.WALLET_AUTH_URL) {
      if (notLoggedInToWallet) {
        return true;
      }
      this.localStorageService.setStorage(WALLET, true);
      this.router.navigate([this._appURLs.WALLET_URL]);
      return false;
    }

    if (state.url === this._appURLs.INCENTIVE_URL) {
      return combineLatest({
        newIncentiveProgram: this._getFeatureFlagUseCase.execute(
          FEATURE_FLAGS.INCENTIVE_PROGRAM_V3,
        ),
        ksaIncentiveProgram: this._getFeatureFlagUseCase.execute(
          FEATURE_FLAGS.KSA_INCENTIVE_PROGRAM,
        ),
      }).pipe(
        map(({ newIncentiveProgram, ksaIncentiveProgram }) => {
          const selectedCountry = country.code;
          if (
            newIncentiveProgram &&
            (country.is('EGY') || (country.is('KSA') && ksaIncentiveProgram))
          ) {
            return true;
          }
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
          return false;
        }),
      );
    }

    if (state.url.startsWith(this._appURLs.ZID_INTEGRATION_URL)) {
      return this._getFeatureFlagUseCase.execute(ZID_INTEGRATION).pipe(
        map((flag) => {
          if (flag) {
            return true;
          }
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
          return false;
        }),
      );
    }

    if (state.url.startsWith(this._appURLs.LEARNING_CENTER_URL)) {
      return this._getFeatureFlagUseCase.execute(ONBOARDING_V2).pipe(
        map((flag) => {
          if (flag) {
            return true;
          }
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
          return false;
        }),
      );
    }
    if (state.url.startsWith(this._appURLs.CREATIVES_URL)) {
      const selectedCountry = country.code.toLocaleLowerCase();
      const isUserFeatureExist = this._checkUserFeatureExistsUseCase.execute(
        `${CREATIVE_REQUEST_FEATURE}${selectedCountry}`,
      );
      return this._getFeatureFlagUseCase.execute(CREATIVES_FEATURE).pipe(
        map((flag) => {
          if (flag && isUserFeatureExist) {
            return true;
          }
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
          return false;
        }),
      );
    }
    if (state.url.startsWith(this._appURLs.LOYALTY_URL)) {
      const isKsaSelected = country.is('KSA');
      return this._getFeatureFlagUseCase.execute(LOYALTY_PROGRAM).pipe(
        map((flag) => {
          if (
            flag &&
            isKsaSelected &&
            this._checkUserFeatureExistsUseCase.execute(LOYALTY_PROGRAM_FEATURE)
          ) {
            return true;
          }
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
          return false;
        }),
      );
    }
    if (state.url.startsWith(this._appURLs.REFERRAL_URL)) {
      const isKsaSelected = country.is('KSA');
      const userHasReferralProgram =
        this._checkUserFeatureExistsUseCase.execute(REFERRAL_PROGRAM_FEATURE);
      return this._getFeatureFlagUseCase.execute(REFERRAL_PROGRAM).pipe(
        map((flag) => {
          if (flag && isKsaSelected && userHasReferralProgram) {
            return true;
          }
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
          return false;
        }),
      );
    }
    if (state.url.startsWith(this._appURLs.MERCHANT_INSIGHTS_URL)) {
      return this._getFeatureFlagUseCase.execute(MERCHANT_INSIGHTS).pipe(
        map((flag) => {
          const userHasMerchantInsights = this._checkUserFeatureExistsUseCase.execute(
            MERCHANT_INSIGHTS_USER_FEATURE,
          );
          if (flag && userHasMerchantInsights) {
            return true;
          }
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
          return false;
        }),
      );
    }
    if (state.url.startsWith(this._appURLs.STORES_URL)) {
      return this._getFeatureFlagUseCase.execute(WEB_STORES).pipe(
        map((flag) => {
          if (flag) {
            return true;
          }
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
          return false;
        }),
      );
    }

    if (state.url === this._appURLs.PRE_ORDER_REQUESTS_URL) {
      return this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PREORDER_V3).pipe(
        map((FeatureIsEnabled) => {
          const selectedCountry = country.code;
          const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
            PRE_ORDER_USER_FEATURE + selectedCountry.toLowerCase(),
          );
          if (!FeatureIsEnabled || !userHasPreOrder) {
            this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
            return false;
          }
          return true;
        }),
      );
    }

    if (
      state.url === this._appURLs.STATISTICS_URL &&
      this._checkUserFeatureExistsUseCase.execute(STATICS_FEATURE)
    ) {
      return true;
    }
    this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
    return false;
  }
}
