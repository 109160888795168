import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class GameCardComponent {
  @Input() gameProfitFactor: number;

  @Input() gameUnlocked: boolean;
}
