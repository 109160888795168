import { DatePipe, NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WalletTransactionModel } from 'src/app/core/domain/wallet/wallet.model';
import { LocalizedComponent } from '../../base/localized.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { dateTimeFormat } from '../../shared/constants';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { ALL_STATUSES } from '../../shared/constants/order-statuses';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { OrderStatusToTranslationKeyPipe } from '../../shared/pipes/order-status-to-translation-key.pipe';
import { TransactionsCategoryToTranslationKeyPipe } from '../../shared/pipes/transactions-category-to-translation-key.pipe';

@Component({
  selector: 'app-wallet-transactions-table',
  templateUrl: './wallet-transactions-table.component.html',
  styleUrls: ['./wallet-transactions-table.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    NgFor,
    NgClass,
    CurrencyTranslatePipe,
    CurrencyShortNamePipe,
    DatePipe,
    LoaderComponent,
    OrderStatusToTranslationKeyPipe,
    UpperCasePipe,
    TranslateModule,
    TransactionsCategoryToTranslationKeyPipe,
  ],
})
export class WalletTransactionsTableComponent extends LocalizedComponent implements OnInit {
  @Input() transactions: WalletTransactionModel[];

  public isEnglishLanguage: boolean;

  public transactionsTypes: any;

  public dateFormat = dateTimeFormat;

  public noResultsFound = false;

  constructor(private _translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.transactionsTypes = {
      order: 'order',
      withdrawal: 'withdrawal',
      compensation: 'compensation',
    };

    this.isEnglishLanguage = this._translateService.currentLang === ENGLISH_LANGUAGE;
    this._translateService.onTranslationChange.subscribe({
      next: ({ lang }: { lang: string }) => {
        this.isEnglishLanguage = lang === ENGLISH_LANGUAGE;
      },
    });

    this.noResultsFound = !this.transactions || this.transactions?.length === 0;
  }

  public translateStatus(value: any): string {
    return this.trans(
      ALL_STATUSES.find((status) => status.statusInEnglish === value)!.statusTranslationKey!,
    );
  }
}
