import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgClass, NgIf } from '@angular/common';
import { user } from '@features/user/data';
import cache from '@mongez/cache';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { UserRepository } from 'src/app/core/repositories/user.repository';
import { ForgotWalletPasswordUseCase } from 'src/app/core/usecases/wallet/forgot-wallet-password.usecase';
import { WalletLoginUseCase } from 'src/app/core/usecases/wallet/wallet-login.usecase';
import { REDIRECT_URL, WALLET } from 'src/app/presentation/shared/constants';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';

@Component({
  selector: 'app-wallet-login',
  templateUrl: './wallet-login.component.html',
  styleUrls: ['./wallet-login.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, FormsModule, ReactiveFormsModule, TranslateModule],
})
export class WalletLoginComponent implements OnInit {
  queryString: any;

  isMobile: boolean;

  orders = [];

  submitted = false;

  loading = false;

  errorMessage = '';

  successMessage = '';

  loginForm: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private _forgotWalletPasswordUseCase: ForgotWalletPasswordUseCase,
    private _walletLoginUseCase: WalletLoginUseCase,
    private responsiveService: ResponsiveService,
    private localStorageService: LocalStorageService,
    private _userRepository: UserRepository,
    private route: Router,
    private routes: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getIsMobile();
    this.routes.queryParams.subscribe({
      next: (params) => {
        this.queryString = params;
      },
    });
  }

  getIsMobile(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
  }

  get password(): AbstractControl {
    return this.loginForm.get('password')!;
  }

  onSubmit(): void {
    this.submitted = true;
    this.errorMessage = '';
    // TODO: Use EventEmitter with form value

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this._walletLoginUseCase
      .execute({
        username: user.email,
        password: this.loginForm.value.password,
      })
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.localStorageService.setStorage(WALLET, true);
          const redirectUrl = cache.get(REDIRECT_URL);

          if (redirectUrl) {
            this.route.navigate([redirectUrl]);
            cache.remove(REDIRECT_URL);
          } else {
            this.route.navigate(['wallet'], {
              queryParams: this.queryString,
            });
          }
        },
        error: (err) => {
          if (err.status === 401) {
            this.successMessage = '';
            this.errorMessage = 'كلمة المرور غير صحيحة';
          } else if (err.status === 404) {
            this.successMessage = '';
            this.errorMessage = 'كلمة المرور غير صحيحة';
          } else {
            this.successMessage = '';
            this.errorMessage = 'يوجد مشكلة في السيرفر، من فضلك حاول مرة أخرى';
          }
        },
      });
  }

  onClickForgotPassword(): void {
    this.loading = true;
    this._forgotWalletPasswordUseCase
      .execute({ email: user.email })
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.errorMessage = '';
          this.successMessage = 'تم إرسال إميل تعديل كلمة سر المحفظة';
        },
        error: (err) => {
          if (err.status === 404) {
            this.successMessage = '';
            this.errorMessage = 'بريد الألكترونى غير مسجل';
          } else {
            this.successMessage = '';
            this.errorMessage = 'يوجد مشكلة في السيرفر، من فضلك حاول مرة أخرى';
          }
        },
      });
  }
}
