import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionsCategoryToTranslationKey',
  pure: false,
  standalone: true,
})
export class TransactionsCategoryToTranslationKeyPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return value.replace(/-/g, ' ').toUpperCase().replace(/\s/g, '_');
  }
}
