<div class="div__milestoneContainer">
  <div class="div__milestone-goal-image">
    <img
      loading="lazy"
      *ngIf="order !== milestonesCount - 1"
      src="assets/img/rewards-program-icons/goal-post.svg"
      class="img__milestonePost"
      [ngClass]="{ dimmed: progress < 100 }"
    />
    <img
      loading="lazy"
      *ngIf="order === milestonesCount - 1"
      src="assets/img/rewards-program-icons/end-flag.svg"
      class="img__milestonePost"
      [ngClass]="{ dimmed: progress < 100 }"
    />
    <div class="div__info-tooltip">
      <p class="tooltip-title">$ مستوي الربح {{ arabicOrder }}</p>
      <p class="tooltip-text">
        ستحصل علي {{ reward }} {{ currency }} عند اتمام {{ target }}
        طلب مؤكد قبل نهاية الشهر.
        <span *ngIf="target > currentOrders" class="remaining-orders"
          >(المتبقي: {{ target - currentOrders }} طلب)</span
        >
      </p>
    </div>
  </div>
  <mat-progress-bar mode="determinate" value="{{ progress }}"></mat-progress-bar>
  <div class="div__milestoneDescription" [ngClass]="{ dimmed: progress < 100 }">
    <p class="milestoneOrdersCount">{{ target }} طلب تم توصيله</p>
    <div class="div__reward">
      <img
        loading="lazy"
        src="assets/img/rewards-program-icons/coin.svg"
        alt=""
        class="img__reward"
      />
      <p class="milestoneReward">+{{ reward }} {{ currency }}</p>
    </div>
  </div>
</div>
