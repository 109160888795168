import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import {
  IncentiveProgramModel,
  OperationRateModel,
} from 'src/app/core/domain/incentive-program.model';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetOperationRateUseCase } from 'src/app/core/usecases/get-operation-rate.usecase';
import { FEATURE_FLAGS } from '../../shared/constants';
import { DeliveredTooltipComponent } from './delivered-tooltip/delivered-tooltip.component';
import { DeliveryRateTooltipComponent } from './delivery-rate-tooltip/delivery-rate-tooltip.component';
import { GameCardComponent } from './game-card/game-card.component';
import { RewardsProgressBarComponent } from './rewards-progress-bar/rewards-progress-bar.component';

@Component({
  selector: 'app-rewards-progress-card',
  templateUrl: './rewards-progress-card.component.html',
  styleUrls: ['./rewards-progress-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    DeliveryRateTooltipComponent,
    DeliveredTooltipComponent,
    RewardsProgressBarComponent,
    GameCardComponent,
  ],
})
export class RewardsProgressCardComponent implements OnInit, OnDestroy {
  @Input() incentiveProgram: IncentiveProgramModel;

  @Input() moreClick: Observable<void>;

  public cappedIncentiveProgramFeatureFLag = false;

  public deliveryRateIncentiveFeatureFlag = false;

  public currency: string;

  public uncappedGameFactor = 0;

  public gameUnlocked = false;

  public cappedUser = false;

  public rewardsProgramAssetsUrl = 'assets/img/rewards-program-icons/';

  private moreClickedSubscription: Subscription;

  public showDeliveredTooltip = false;

  public showDeliveryRateToolTip = false;

  public operationRates: OperationRateModel;

  constructor(
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getOperationRateUseCase: GetOperationRateUseCase,
    private _toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.currency = 'EGP';
    this.calculateGameData();
    this.getOperationRates();
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.CAPPED_INCENTIVE_PROGRAM_FLAG).subscribe({
      next: (flag) => {
        if (flag) {
          this.cappedIncentiveProgramFeatureFLag = flag;
          if (this.cappedIncentiveProgramFeatureFLag) {
            this.cappedUser = this.incentiveProgram.incentiveProgramType === 'limited';
          }
        }
      },
    });
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.DELIVERY_RATE_INCENTIVE_FLAG).subscribe({
      next: (flag) => {
        if (flag) {
          this.deliveryRateIncentiveFeatureFlag = flag;
        }
      },
    });

    this.moreClickedSubscription = this.moreClick.subscribe({
      next: () => {
        this.toggleDeliveredTooltip();
      },
    });
  }

  getOperationRates(): void {
    this._getOperationRateUseCase.execute().subscribe({
      next: (res) => {
        this.operationRates = res;
      },
      error: (err) => {
        this._toastrService.error('Error getting delivery rate', 'Error');
      },
    });
  }

  toggleDeliveredTooltip(): void {
    this.showDeliveredTooltip = !this.showDeliveredTooltip;
  }

  toggleDeliveryRateTooltip(): void {
    this.showDeliveryRateToolTip = !this.showDeliveryRateToolTip;
  }

  calculateGameData() {
    const lastMilestone =
      this.incentiveProgram.milestones[this.incentiveProgram.milestones.length - 1];
    this.uncappedGameFactor = lastMilestone.reward / lastMilestone.target;
    this.gameUnlocked = this.incentiveProgram.currentOrders >= lastMilestone.target;
  }

  ngOnDestroy(): void {
    this.moreClickedSubscription.unsubscribe();
  }
}
