import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-delivery-rate-tooltip',
  templateUrl: './delivery-rate-tooltip.html',
  styleUrls: ['./delivery-rate-tooltip.component.scss'],
  standalone: true,
})
export class DeliveryRateTooltipComponent {
  @Output() dismissTooltip$: EventEmitter<boolean>;

  constructor() {
    this.dismissTooltip$ = new EventEmitter<boolean>();
  }

  public dismissTooltip(): void {
    this.dismissTooltip$.next(true);
  }
}
