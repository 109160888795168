<div class="div__progressBarContainer">
  <app-milestone
    *ngFor="let milestone of milestones; index as index"
    [order]="index"
    [target]="milestone.target"
    [reward]="milestone.reward"
    [progress]="milestone.progress"
    [milestonesCount]="milestones.length"
    [currency]="currency"
    [currentOrders]="currentOrders"
  ></app-milestone>

  <div
    *ngIf="currentOrders < target"
    class="div__runnerProgress"
    [style.right.%]="(runnersProgress < 100 ? runnersProgress : 100) - 3"
  >
    <img
      loading="lazy"
      class="img__runner"
      src="assets/img/rewards-program-icons/runner.svg"
      alt=""
    />
    <div
      class="runner-progress-text"
      [ngClass]="{
        'runner-progress-text--textBefore': progressPerecentage <= 50
      }"
    >
      <p>{{ progressPerecentage }}%</p>
    </div>
  </div>
</div>
