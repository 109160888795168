import { NgIf } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { user } from '@features/user/data';
import { TranslateModule } from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs';
import {
  WalletTransactionModel,
  WalletTransactionRequestFilterModel,
  WalletTransactionType,
} from 'src/app/core/domain/wallet/wallet.model';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetTransactionsSheetUseCase } from 'src/app/core/usecases/wallet/get-transactions-sheet.usecase';
import { GetWalletTransactionHistoryUseCase } from 'src/app/core/usecases/wallet/get-wallet-transaction.usecase';
import { DateWrapperUtility } from 'src/app/presentation/shared/utilities/date-wrapper.utility';
import { dateTypeValidator } from '../../profile/shared/validators/date-type.validator';
import { fromDateToDateValidator } from '../../profile/shared/validators/from-date-to-date.validator';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { WALLET_TRANSACTIONS_HISTORY } from '../../shared/constants/feature-flags';
import { Currency } from '../../shared/interfaces/countries';
import { featureAttributeAssign } from '../../shared/utilities/feature-attribute-assign.utility';
import { DownloadTransactionsLimitPopUpComponent } from '../download-transactions-limit-pop-up/download-transactions-limit-pop-up.component';
import { WalletTransactionsHistoryFiltersComponent } from '../wallet-transactions-history-filters/wallet-transactions-history-filters.component';
import { WalletTransactionsTableComponent } from '../wallet-transactions-table/wallet-transactions-table.component';

@Component({
  selector: 'app-wallet-transaction-history',
  templateUrl: './wallet-transaction-history.component.html',
  styleUrls: ['./wallet-transaction-history.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    WalletTransactionsHistoryFiltersComponent,
    WalletTransactionsTableComponent,
    PaginationModule,
    FormsModule,
    LoaderComponent,
  ],
})
export class WalletTransactionHistoryComponent implements OnInit {
  @Input() currenciesOptions: Currency[];

  public currentPage = 1;

  public pageSize = 10;

  public transactionsCount = 0;

  public transactionsHistoryFilterForm: FormGroup<{
    currency: FormControl<string>;
    type: FormControl<string | null>;
    fromDate: FormControl<string | null>;
    toDate: FormControl<string | null>;
  }>;

  public transactions: WalletTransactionModel[];

  public isLoading = false;

  public isLoaded = false;

  public shouldShowTransactionTable = false;

  private _getTransactionsSheetUseCase = inject(GetTransactionsSheetUseCase);

  private _dialog = inject(MatDialog);

  private _getWalletTransactionsHistoryUseCase: GetWalletTransactionHistoryUseCase = inject(
    GetWalletTransactionHistoryUseCase,
  );

  private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase = inject(
    GetFeatureAttributeUsecase,
  );

  ngOnInit(): void {
    this._getFeatureFlag();
    this._initializeForm();
    this.getWalletTransactionsHistory();
  }

  public getWalletTransactionsHistory(): void {
    if (!this.transactionsHistoryFilterForm.valid) {
      return;
    }
    this.isLoading = true;
    const params = this._getFilters();
    this._getWalletTransactionsHistoryUseCase
      .execute(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: ({ transactions, count }) => {
          this.transactions = transactions;
          this.transactionsCount = count;
          this.isLoaded = true;
        },
      });
  }

  public onDownloadExcel(): void {
    const params = this._getFilters();
    this.isLoading = true;
    this._getTransactionsSheetUseCase
      .execute(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (response) => {
          const reader: FileReader = new FileReader();
          reader.readAsBinaryString(response);

          reader.onload = (event: any): void => {
            const dataLength = JSON.stringify(event?.target?.result).split('\\n').length;
            if (dataLength > 999) {
              this._dialog.open(DownloadTransactionsLimitPopUpComponent, {
                width: '750px',
                panelClass: 'linked-store-dialog',
              });
            }
            fileSaver.saveAs(response, 'transactions.csv');
          };
        },
      });
  }

  public pageChanged({ page }: any): void {
    this.currentPage = page;
    document
      .getElementsByClassName('wallet-transactions')[0]
      .scrollIntoView({ behavior: 'smooth' });
    this.getWalletTransactionsHistory();
  }

  private _getFeatureFlag(): void {
    this._getFeatureAttributeUseCase.execute(WALLET_TRANSACTIONS_HISTORY).subscribe({
      next: (featureAttribute) => {
        this.shouldShowTransactionTable = featureAttributeAssign(featureAttribute, user.id);
      },
    });
  }

  private _initializeForm(): void {
    this.transactionsHistoryFilterForm = new FormGroup(
      {
        currency: new FormControl(
          this.currenciesOptions[0].englishName,
          Validators.required,
        ) as FormControl<string>,
        type: new FormControl(''),
        fromDate: new FormControl('', [dateTypeValidator]),
        toDate: new FormControl('', [dateTypeValidator]),
      },
      [fromDateToDateValidator('fromDate', 'toDate')],
    );
    this.transactionsHistoryFilterForm.updateValueAndValidity();
  }

  private _getFilters(): WalletTransactionRequestFilterModel {
    const { type, currency } = this.transactionsHistoryFilterForm.value!;
    return {
      page: this.currentPage - 1,
      pageSize: this.pageSize,
      currency,
      ...(type ? { type: type as WalletTransactionType } : {}),
      fromDate: this._getFormattedDateFromStringDate(
        this.transactionsHistoryFilterForm.value.fromDate!,
      ),
      toDate: this._getFormattedDateFromStringDate(
        this.transactionsHistoryFilterForm.value.toDate!,
      ),
    };
  }

  private _getFormattedDateFromStringDate(
    dateString: string,
    format = 'YYYY-M-D',
  ): string | undefined {
    return dateString
      ? DateWrapperUtility.formatDateToString(new Date(dateString), format)
      : undefined;
  }
}
