<table class="wallet-transactions-table">
  <tr class="wallet-transactions-table__row">
    <th class="wallet-transactions-table__header caption2--medium">
      {{ 'WALLET.TRANSACTION_HISTORY.TABLE.TRANSACTION_DATE' | translate }}
    </th>
    <th class="wallet-transactions-table__header caption2--medium">
      {{ 'WALLET.TRANSACTION_HISTORY.TABLE.AMOUNT' | translate }}
    </th>
    <th
      class="wallet-transactions-table__header wallet-transactions-table__type-header caption2--medium"
    >
      {{ 'WALLET.TRANSACTION_HISTORY.TABLE.TYPE' | translate }}
    </th>
    <th class="wallet-transactions-table__header caption2--medium">
      {{ 'WALLET.TRANSACTION_HISTORY.TABLE.NOTES' | translate }}
    </th>
  </tr>
  <tr
    *ngFor="let transaction of transactions; let index = index"
    class="wallet-transactions-table__row"
    [ngClass]="{
      'wallet-transactions-table__last-row': index === transactions.length - 1,
    }"
  >
    <td class="wallet-transactions-table__date">
      <bdi>{{ transaction.transactionDate | date : dateFormat }}</bdi>
    </td>
    <td
      class="wallet-transactions-table__amount"
      [ngClass]="
        transaction.amount >= 0
          ? 'wallet-transactions-table__amount--green'
          : 'wallet-transactions-table__amount--red'
      "
    >
      {{ transaction.amount }}
      {{ transaction.currency | currencyTranslate | translate | currencyShortName }}
    </td>
    <td class="wallet-transactions-table__type">
      <span class="caption1--medium">
        {{
          'WALLET.TRANSACTION_HISTORY.TABLE.CATEGORY.' +
            (transaction.category | transactionsCategoryToTranslationKey) | translate
        }}
      </span>
      <span
        class="caption2--regular content-medium"
        *ngIf="transaction.details.type === transactionsTypes.order; else withdrawalCompensation"
      >
        {{ 'WALLET.TRANSACTION_HISTORY.TABLE.ORDER' | translate }}:
        {{ transaction.details.typeId }} {{ 'WALLET.TRANSACTION_HISTORY.TABLE.FROM' | translate }}
        <span>{{ translateStatus(transaction.details.fromStatus) }}</span>
        {{ 'WALLET.TRANSACTION_HISTORY.TABLE.TO' | translate }}
        <span>{{ translateStatus(transaction.details.toStatus) }}</span>
      </span>
      <ng-template #withdrawalCompensation>
        <span class="caption2--regular content-medium">
          {{
            'WALLET.TRANSACTION_HISTORY.TABLE.' + (transaction.details.type | uppercase)
              | translate
          }}:
          {{ transaction.details.typeId }}
        </span>
      </ng-template>
    </td>
    <td class="wallet-transactions-table__notes">{{ transaction.notes }}</td>
  </tr>
  <tr>
    <td
      *ngIf="noResultsFound"
      class="wallet-transactions-table__no-results caption1--medium"
      colspan="100"
    >
      {{ 'WALLET.TRANSACTION_HISTORY.TABLE.NO_RESULTS' | translate }}
    </td>
  </tr>
</table>
