import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationGuard } from '../shared/guards/authorization.guard';
import { DownloadTransactionsLimitPopUpComponent } from './download-transactions-limit-pop-up/download-transactions-limit-pop-up.component';
import { DeliveredTooltipComponent } from './rewards-progress-card/delivered-tooltip/delivered-tooltip.component';
import { DeliveryRateTooltipComponent } from './rewards-progress-card/delivery-rate-tooltip/delivery-rate-tooltip.component';
import { GameCardComponent } from './rewards-progress-card/game-card/game-card.component';
import { MilestoneComponent } from './rewards-progress-card/rewards-progress-bar/milestone/milestone.component';
import { RewardsProgressBarComponent } from './rewards-progress-card/rewards-progress-bar/rewards-progress-bar.component';
import { RewardsProgressCardComponent } from './rewards-progress-card/rewards-progress-card.component';
import { WalletCardComponent } from './wallet-card/wallet-card.component';
import { WalletLoginComponent } from './wallet-login/wallet-login.component';
import { WalletTransactionHistoryComponent } from './wallet-transaction-history/wallet-transaction-history.component';
import { WalletTransactionsHistoryFiltersComponent } from './wallet-transactions-history-filters/wallet-transactions-history-filters.component';
import { WalletComponent } from './wallet.component';
import { WithdrawalDialogComponent } from './withdrawal-dialog/withdrawal-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    WalletComponent,
    WalletLoginComponent,
    WalletCardComponent,
    WithdrawalDialogComponent,
    RewardsProgressCardComponent,
    RewardsProgressBarComponent,
    MilestoneComponent,
    GameCardComponent,
    DeliveredTooltipComponent,
    DeliveryRateTooltipComponent,
    WalletTransactionHistoryComponent,
    WalletTransactionsHistoryFiltersComponent,
    DownloadTransactionsLimitPopUpComponent,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        component: WalletComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'auth',
        component: WalletLoginComponent,
        canActivate: [AuthorizationGuard],
      },
    ]),
  ],
})
export class WalletModule {}
