import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-download-transactions-limit-pop-up',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './download-transactions-limit-pop-up.component.html',
  styleUrls: ['./download-transactions-limit-pop-up.component.scss'],
})
export class DownloadTransactionsLimitPopUpComponent {
  private _dialog = inject(MatDialog);

  closeDialog(): void {
    this._dialog.closeAll();
  }
}
