import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { QuestionnaireModel } from 'src/app/core/domain/questionnaire.model';
import { GetQuestionnaireQuestionsUseCase } from 'src/app/core/usecases/get-questionnaire-questions.usecase';
import { DeclineQuestionnaireUseCase } from 'src/app/core/usecases/post-decline-questionnaire';
import { SubmitQuestionnaireAnswersUseCase } from 'src/app/core/usecases/submit-questionnaire-answers.usecase';
import { WALLET_SURVEY_NAME } from '../../shared/constants';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { MixpanelService } from '../../shared/services/mixpanel.service';
import { SiteTranslateService } from '../../shared/services/translate.service';

@Component({
  selector: 'app-wallet-transaction-survey',
  templateUrl: './wallet-transaction-survey.component.html',
  styleUrls: ['./wallet-transaction-survey.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, NgClass, TranslateModule],
})
export class WalletTransactionSurveyComponent implements OnInit {
  public assetsPath = 'assets/img/';

  public isEnglishLanguage = false;

  public questionsList: QuestionnaireModel;

  public selectedIndex: number;

  public questionId: string;

  public answerId: string;

  constructor(
    @Inject(SiteTranslateService) private _siteTranslateService: any,
    private _translateService: TranslateService,
    private _matDialog: MatDialog,
    private _getQuestionnaireQuestionsUseCase: GetQuestionnaireQuestionsUseCase,
    private _submitQuestionnaireAnswersUseCase: SubmitQuestionnaireAnswersUseCase,
    private _toast: ToastrService,
    private _mixpanelService: MixpanelService,
    private _declineQuestionnaireUseCase: DeclineQuestionnaireUseCase,
  ) {}

  ngOnInit(): void {
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this.fetchQuestionnaire();
  }

  public closeDialog(): void {
    this._matDialog.closeAll();
  }

  public fetchQuestionnaire(): void {
    this._getQuestionnaireQuestionsUseCase.execute(WALLET_SURVEY_NAME).subscribe((quesionnaire) => {
      this.questionsList = quesionnaire;
    });
  }

  public selectAnswer(index: any, question: any): void {
    this.selectedIndex = index;
    this.questionId = question.id;
    this.answerId = question.answers[index].id;
  }

  public submitAnswer(): void {
    if (!this.answerId) {
      this._toast.error(this._translateService.instant('WALLET.WALLET_SURVEY.SELECT_VALUE'));
      return;
    }
    const answer = {
      answers: [
        {
          questionId: this.questionId,
          answerIds: [this.answerId],
        },
      ],
    };
    this._submitQuestionnaireAnswersUseCase
      .execute({
        questionnaireName: WALLET_SURVEY_NAME,
        data: answer,
      })
      .subscribe({
        next: () => {
          this._mixpanelService.track('wallet_transaction_survey_submit_success', {
            answer_id: this.answerId,
          });
          this.closeDialog();
        },
        error: (err) => {
          this._mixpanelService.track('wallet_transaction_survey_submit_error', {
            errorMessage: err,
          });
        },
        complete: () => {
          this._toast.success(this._translateService.instant('SURVEY.ANSWER_RECORDED'));
        },
      });
  }

  public declineQuestionnaire(): void {
    this._declineQuestionnaireUseCase.execute(WALLET_SURVEY_NAME).subscribe({
      next: () => {
        this._mixpanelService.track('wallet_transaction_survey_declines');
        this.closeDialog();
      },
      error: (err) => {
        this._mixpanelService.track('wallet_transaction_survey_decline_error', {
          errorMessage: err,
        });
      },
      complete: () => {
        this._toast.success(this._translateService.instant('SURVEY.QUESTIONNAIRE_REJECTED'));
      },
    });
  }
}
